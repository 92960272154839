import { ChatsFacade } from './services/facades/chats/chats.facade';
import { FacilityModel } from './../../models/facility/facility.model';
import { Component, OnInit, OnDestroy, ViewContainerRef, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

import { FlowService } from './services/flow.service';
import { FacilitiesFacade } from './services/facades/facilities/facilities.facade';

import { AuthFacade } from 'src/app/services/facades/auth/auth.facade';
import { ChildToParentDataService } from './services/child-to-parent-data.service';
import { NotificationsAccessor } from './services/accessors/notifications.accessor';
import { FacilitiesAccessor } from './services/accessors/facilities.accessor';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { UserModel } from 'src/app/models/user';
import { UserAccessor } from 'src/app/services/accessors/user.accessor';
import { NotificationsFacade } from './services/facades/notifications/notifications.facade';
import { UserFacade } from 'src/app/services/facades/user/user.facade';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { ServicedStatesFacade } from './services/facades/serviced-states/serviced-states.facade';
import { ModalService } from './services/ui/modal.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MessagesService } from './services/messages.service';
import { IntercomService } from './services/intercom.service';
import { SelectFacilityService } from './services/select-facility.service';
import { ScrollService } from './services/ui/scroll.service';
import { PopupService } from './services/ui/popup.service';
import * as braze from "@braze/web-sdk";

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss'],
})
export class ManagerComponent implements OnInit, OnDestroy, AfterViewInit {
  destroyed$: Subject<boolean> = new Subject<boolean>();
  user: UserModel;
  selectedFacility: FacilityModel;
  facilitySubscription: Subscription = new Subscription();
  facilities: Array<FacilityModel>;
  filteredFacilities: Array<FacilityModel>;
  showDropDown = false;
  chatsUnreadCount$ = this.notificationsAccessor.chatsUnreadCount$;
  shiftsUpdatedCount$ = this.notificationsAccessor.shiftsUpdatedCount$;
  mobileMenu = false;
  filterInputForm = this.fb.group({
    facilityName: [''],
  });
  @ViewChild('modal', { read: ViewContainerRef }) modalEntry;
  @ViewChild('contentContainer') contentContainer: ElementRef;
  @ViewChild('popup', { read: ViewContainerRef }) popupContainer: ViewContainerRef;

  private scrollSubscription: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authFacade: AuthFacade,
    private userAccessor: UserAccessor,
    private notificationsAccessor: NotificationsAccessor,
    private facilitiesAccessor: FacilitiesAccessor,
    private notificationsFacade: NotificationsFacade,
    public router: Router,
    private childDataService: ChildToParentDataService,
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private intercomService: IntercomService,
    private selectFacilityService: SelectFacilityService,
    private scrollService: ScrollService,
    private popupService: PopupService
  ) {
    //flowService.register();
    this.scrollSubscription = this.scrollService.scrollToTop$.subscribe(() => {
      this.contentContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
  ngOnInit() {

    this.router.events.pipe(
      takeUntil(this.destroyed$),
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      tap((e) => {
        this.mobileMenu = false;
        this.intercomService.update();
      })
    ).subscribe()


    this.breakpointObserver.observe(['(min-width: 1365.02px)'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(result => {
        if (result.matches) this.mobileMenu = false;
      });

    this.userAccessor.user$.pipe(take(1)).subscribe((v) => {
      this.user = v;
    });
    this.facilitySubscription.add(
      this.facilitiesAccessor.activeFacility$.subscribe((f) => {
        this.selectedFacility = f;
      }),
    );
    this.facilitySubscription.add(
      this.facilitiesAccessor.myFacilities$.subscribe((f) => {
        this.facilities = Object.values(f);
        this.filteredFacilities = Object.values(f);
        this.sortFacilities(this.filteredFacilities);
      }),
    );

    combineLatest([this.facilitiesAccessor.myFacilities$, this.userAccessor.user$]).pipe(
      takeUntil(this.destroyed$))
      .pipe(filter(([facilities, user]) => user != undefined && Object.keys(facilities).length != 0))
      .subscribe(([facilities, user]) => {
        this.intercomService.boot(user, Object.values(facilities))
      }
      );

    this.notificationsFacade.fetchNotifications().pipe(take(1)).subscribe();
    this.childDataService.data$.subscribe((res) => {
      this.mobileMenu = res;
    });
  }
  ngAfterViewInit(): void {
    this.modalService.entry = this.modalEntry;
    this.popupService.setViewContainerRef(this.popupContainer);
  }
  ngOnDestroy() {
    this.facilitySubscription.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.scrollSubscription.unsubscribe();
  }
  onSelectFacility(facility) {
    this.selectFacilityService.selectFacility(facility);
    this.showDropDown = false;
  }
  onLogOut() {
    this.modalService.openModal('', 'Are you sure you want to log out?', 'Log out').pipe(take(1)).subscribe(r => {
      if (r.action === 'firstAction') {
        this.intercomService.shutdown();
        this.authFacade.logout().pipe(take(1)).subscribe();
      }
    });
  }
  onDropDownClick() {
    this.showDropDown = !this.showDropDown;
  }
  onFilterInputChange(e) {
    let value = e.srcElement.value.replace(/\s+/, '');
    if (!value) {
      this.filteredFacilities = this.facilities;
      return;
    }
    this.filteredFacilities = this.facilities.filter((f) => {
      if (
        f.name.replace(/\s+/, '').toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  }
  sortFacilities(facilities) {
    facilities.sort((a, b) => a.name.localeCompare(b.name));
  }
}
