import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';

import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  pluck,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthAccessor } from 'src/app/services/accessors/auth.accessor';

import { AuthService } from 'src/app/services/api/auth.service';

import * as AuthActions from '../actions/auth/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authApi: AuthService,
    private cookieService: CookieService,
    private authAccessor: AuthAccessor,
  ) {}

  setToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.setToken),
        tap(({ token }) => {
          this.authAccessor.remember$.pipe(take(1)).subscribe((remember) => {
            if (remember) {
              if (token) {
                localStorage.setItem('fm-token', token);
              } else localStorage.removeItem('fm-token');
            }
          });
        }),
      ),
    { dispatch: false },
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap((action) =>
        this.authApi.login(action.email, action.password, action.remember).pipe(
          map(({ accessToken: token, refreshToken }) =>
            AuthActions.loginSuccess({
              token,
              remember: action.remember,
            }),
          ),
          catchError((e) => of(AuthActions.loginFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(({ token }) =>
        AuthActions.setToken({ token }),
      ),
      share(),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(ofType(AuthActions.logout)),
  ).pipe(share());

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      exhaustMap(() =>
        this.authApi.refreshToken().pipe(
          switchMap(({ accessToken: token }) =>{ 
            if (token) {
              localStorage.setItem('fm-token', token);
            }
            return [
            AuthActions.setToken({ token }),
            AuthActions.refreshTokenSuccess(),
          ]}),
          catchError((e) => of(AuthActions.refreshTokenFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  refreshTokenFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenFailed),
      map(() => AuthActions.logout()),
    ),
  );
  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      pluck('email'),
      exhaustMap((email) =>
        this.authApi.forgotPassword(email).pipe(
          map(() => AuthActions.forgotPasswordSuccess()),
          catchError((e) => of(AuthActions.forgotPasswordFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      exhaustMap(({ _id, password, token }) =>
        this.authApi.resetPassword(_id, password, token).pipe(
          map(() => AuthActions.resetPasswordSuccess()),
          catchError((error) => of(AuthActions.resetPasswordFailed({ error }))),
        ),
      ),
      share(),
    ),
  );
}
