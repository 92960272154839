import { Injectable } from "@angular/core";
import * as braze from "@braze/web-sdk";
import { ManagerModel } from "src/app/models/manager";
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BrazeService {

    private brazeApiKey = environment.brazeApiKey; 

    constructor() {}

    initialize(isLoggedIn: boolean, userId: string) {
        // initialize the SDK
        braze.initialize(this.brazeApiKey, {
            baseUrl: 'sdk.iad-07.braze.com',
            sessionTimeoutInSeconds: 900,
            allowUserSuppliedJavascript: true,
            safariWebsitePushId: 'web.com.eshyft.webapp'
        },);

        // optionally show all in-app messages without custom handling
        braze.automaticallyShowInAppMessages();

       // braze.requestPushPermission();

        // if you use Content Cards
        braze.subscribeToContentCardsUpdates(function(cards){
            // cards have been updated
        });

        // optionally set the current user's external ID before starting a new session
        // you can also call `changeUser` later in the session after the user logs in
        if (isLoggedIn){
            braze.changeUser(userId);
        }

        // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
        braze.openSession();
    }

    setUserAttributes(user: ManagerModel) {
        braze.getUser().setFirstName(user.name.first);
        braze.getUser().setLastName(user.name.last);
        braze.getUser().setEmail(user?.email);
        braze.getUser().setHomeCity(user.address?.city);
        braze.getUser().setGender(this.getGender(user?.gender));
        braze.getUser().setPhoneNumber(user?.phone);
    }

    getGender(gender: string) {
       switch(gender) {
        case 'male': return 'm';
        case 'female': return 'f';
        case 'unknown': return 'u';
        case 'other': return 'o';
        default: return null;
       }
    }

    logCustomEvent(eventName: string, payload: IPayload = {}, shifts?: IPayload[]) {
        const payloadToLog = shifts ? {...this.mapEventPayload(payload), shifts: shifts.map(s => this.mapEventPayload(s))} : this.mapEventPayload(payload);
        braze.logCustomEvent(eventName, payloadToLog);
    }

    mapEventPayload(payload: IPayload) {
        return {
            'Facility ID': payload.facilityId,
            'Nurse ID': payload.nurseId,
            'Shift ID': payload.shiftId,
            'Timecard ID': payload.timecardId,
            'Shift Start Time': payload.shiftStartTime,
            'Shift End Time': payload.shiftEndTime,
            'Trusted nurse': payload.trustedNurse,
            'Returning nurse': payload.returningNurse,
            'Hourly rate': payload.hourlyRate,
            'Instashyft': payload.instashyft,
            'Approved timecard sequence number': payload.approvedTimecardSequenceNumber,
            'Autoconfirm group': payload.autoConfirmGroup,
            'Environment': 'FM_Web'
        }
    }

}

export const EVENT_OPTIONS = {
    CANCEL_NURSE: 'fm_slot_cancel',
    CONFIRM_NURSE: 'fm_slot_confirm',
    REJECT_TIMECARD: 'fm_timecard_reject',
    DECLINE_APPLICANT: 'fm_applicant_decline',
    SHIFT_POSTED: 'fm_shift_posted',
    APPROVE_TIMECARD: 'fm_timecard_approve',
    MARK_TRUSTED: 'fm_trusted_mark'
}

interface IPayload {
    facilityId?: string, 
    nurseId?: string, 
    shiftId?: string,
    shiftStartTime?: string,
    shiftEndTime?: string,
    hourlyRate?: number,
    returningNurse?: boolean,
    trustedNurse?: boolean,
    instashyft?: boolean
    timecardId?: string;
    autoConfirmGroup?: string;
    approvedTimecardSequenceNumber?: number;
}