import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Chain } from 'src/app/classes/async-chain';
import { POSITIONS } from 'src/app/constants/positions';
import { UserFacade } from 'src/app/services/facades/user/user.facade';
import { State } from 'src/app/state/interfaces';
import { logoutSuccess } from 'src/app/state/actions/auth/auth.actions';
import { AuthEffects } from 'src/app/state/effects/auth.effects';

import { AuthFacade } from './facades/auth/auth.facade';
import { AppService } from './app.service';
import { FlowService as ManagerFlowService } from '../modules/manager/services/flow.service'
import { MixpanelService } from './mixpanel.service';
import branch from 'branch-sdk';
import { BrazeService } from '../modules/manager/services/braze.service';

@Injectable({ providedIn: 'root' })
export class FlowService {
  private _init = new Chain({ debug: !this.app.isProduction, id: 'init' })
    .add('async', {
      token: () => localStorage.getItem('fm-token') || null,
      
    })
    .add('sync', '', ({ token }) =>
      this.authFacade.setToken(token),
    )
    .add('sync', 'profile', ({ token }) => { 
      if(token){
        return this.userFacade.getProfile()    
      }else{
        return null
      }
    })
    .add('sync', '', ({ profile }) => {
      if(profile['user'] && profile['user'].position === 'admin'){
        this._logout.execute();
      }
    })
    
    

  private _login = new Chain({ debug: !this.app.isProduction, id: 'login' })
    .add('sync', '', () => this.userFacade.getProfile())
    .add('sync', 'user', () => this.userFacade.state.user$.pipe(take(1)))
    .add('sync', 'position', ({ user }) => user.position)
    .add('sync', '', ({ position }) => {
      if (!POSITIONS.ADMIN.includes(position)) {
        this.router.navigateByUrl('manager');
        this.mixpanel.for('fm').track('LOGIN');
      }else{
        this._logout.execute();
        window.location.href = environment.loginRedirectUrl;
      }
    });
    

  private _logout = new Chain({ debug: !this.app.isProduction, id: 'logout' })
    .add('async', {
      token: () => localStorage.removeItem('fm-token'),
      mixpanelOptOut: () => this.mixpanel.onLogout()
    })
    .add('sync', '', () => this.store.dispatch(logoutSuccess()))
    .add('sync', '', () => this.router.navigateByUrl('/'));

  constructor(
    private app: AppService,
    private store: Store<State>,
    private authEffects: AuthEffects,
    private authFacade: AuthFacade,
    private userFacade: UserFacade,
    private router: Router,
    private managerFlowService: ManagerFlowService,
    private mixpanel: MixpanelService,
    private brazeService: BrazeService
  ) {
    app.expose('services', 'flow', this);
  }

  register() {
    this._init.execute();
    this._login.startOn(this.authEffects.login$);
    this._logout.startOn(this.authEffects.logout$);
    this.userFacade.effects.getProfile$.subscribe((p)=>{
      if(p['user'] && p['user'].position != 'admin'){
        this.managerFlowService.register();
        this.brazeService.initialize(true, p['user']._id);
        this.brazeService.setUserAttributes(p['user']);
      }
    });
    branch.init(environment.branchKey, function(err, data) {
      // console.log('branch.init', err, data);
    });
  }
}
